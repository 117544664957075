<template>
  <v-container>
    <v-card class="pa-0" :loading="loading">
      <v-card-title> Edit Email Template</v-card-title>
      <v-divider></v-divider>
        <v-card-text>
        <div>
        <p class="">
            <v-text-field
              v-model="template_data.subject"
              dense
              label="Subject"
              hide-details=""
              outlined
            ></v-text-field>
        </p>
        <p>
          <span class="font-weight-bold">Variable Name : </span><span>{{template_data.variables}}</span>
        </p>
        </div>
        <vue-editor 
          v-model="template_data.text"
          style="height: 70vh;"
        >
        </vue-editor>        
        <v-btn
            class="mr-4 mt-14"
            color="secondary"
            @click="updateTemplate()"
            :loading="loading"
            :disabled="loading"
          >submit</v-btn>
      </v-card-text>
  
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { VueEditor, Quill  } from "vue2-editor";
var Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

export default {
  name: "EditTemplates",
  data() {
    return {
      template_data: {},
      loading: false
    };
  },
  components: {
    VueEditor
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getTemplateDetails() {
      let _self = this;
      _self.loading = true;
      this.$axios
        .get(`admin/email_template/details/${this.$route.params.id}`)
        .then((res) => {
          _self.form_error = {};
          if (res.status) {
            _self.template_data = res.data.data;
            var doc = new DOMParser().parseFromString(_self.template_data?.text, "text/html");
            _self.template_data.text = doc.documentElement.textContent;
                  _self.loading = false;
          } else {
            let errors = res.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    updateTemplate() {
      let _self = this;
      this.loading = true;
      this.$axios.put(`admin/email_template/edit/${this.template_data.id}`, this.template_data)
        .then((response) => {
          if (response.data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.$router.push("/templates");
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
   mounted() {
    this.getTemplateDetails();
  },
};
</script>